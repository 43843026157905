//css variables
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 1000px,
        xl: 1000px
);

$primary:#225d32;

@import "~bootstrap/scss/bootstrap";

body {background-color: #eae4d4;font-family: 'Roboto', sans-serif;font-size: 16px;}

h1,h2,h3,h4{font-family: 'Libre Baskerville', serif;}
h1 {color: #333;font-size: 2rem;margin-top:1rem;}
h2 {font-size: 24px;margin: 20px 0 10px;color: #222;}
a {color: #000bbb;}
a:hover {color: #000bbb;}
hr {margin: 30px 0;}

.container {background-color: #fff;box-shadow: 0 0 5px #ccc;position: relative;overflow: hidden;}

nav.leftmenu {
  width: 230px;min-height: 100%;position: absolute;top: 0;left: 0;background-color: #317a45;box-sizing: border-box;border: 1px solid #225d32;transform: translate(-230px, 0);transition: transform .3s ease-in-out, margin .3s ease-in-out;
  &.slide {transform: translate(0px, 0);transition: transform .3s ease-in-out, margin .3s ease-in-out;}
  .logoholder {padding: 30px 0;text-align: center;}
  .logoholder img {display: inline-block;width: 80%;max-width: max-content;}

  ul.menu {
    list-style: none;padding: 0;margin: 0;border-top: 1px solid #225d32;line-height: 52px;font-size: 16px;font-family: "Myriad Pro, Verdana", sans-serif;
    li {
      border-bottom: 1px solid #225d32;color: #fff;position: relative;
      a {
        display: block;color: #fff;text-decoration: none;cursor:pointer;padding: 0 20px;
        &:hover {color: #8ebb16;}
        i {margin-right: 10px;}
      }
      .fa-plus {font-size: 12px;position: absolute;right: 15px;top: 22px;}
      &.active {
        a {background-color: #8ebb16;color: #225d32;}
        ul.sub {display: block;}
      }
    }
  }
  ul.sub {
    list-style: none;padding: 0;margin: 0;line-height: 20px;font-size: 16px;background-color: #225d32;display: none;
    li {
      border: none;padding: 0 30px;
      a {
        border-bottom: 1px solid #317a45;padding: 9px 0;color:#fff !important;background:none !important;
        &:hover, &.active {background: none;color: #8ebb16 !important;}
        &:hover::after, &.active::after {display: block;font-family: "Font Awesome 5 Free", sans-serif;font-weight: 900;content: '\f105';position: absolute;right: 15px;top: 9px;}
      }
      &:last-child a {border: none;}
    }
  }
}

header.mobile {
  display: block;width: 100%;background-color: #317a45;min-height: 50px;border-bottom: 1px solid #225d32;color: #fff;text-align: center;position: relative;cursor: pointer;
  img {height: 40px;margin: 5px 0;}
  .togglebtn-leftmenu {display: block;height: 50px;width: 50px;color: #fff;text-align: center;font-size: 26px;line-height: 54px;position: absolute;left: 0;top: 0;}
}

.rightside {
  transform: translate(0px, 0);transition: transform .3s ease-in-out, width .3s ease-in-out;width:100%;
  &.slide {transform: translate(230px, 0);transition: transform .3s ease-in-out, margin .3s ease-in-out;}
}

.picholder {
  overflow: hidden;border-bottom: 2px solid #eae4d4;position:relative;
  //@include media-breakpoint-up(lg){height:240px;}
  img {display: block;width: 100%;position:absolute;top:0;left:0;}
  img.transparent{opacity:0;position:inherit;}
}

.content {padding: 0 30px 50px 30px;}

nav.breadcrumbs {
  color: #999;padding: 10px 0;
  ul {
    list-style: none;padding: 0;margin: 0;font-size: 12px;
    li {
      float: left;margin-right: 10px;
      a {
        display: block;color: #999;
        &:hover {color: #000;}
      }
    }
  }
}

.datearticle {
  float: right;font-size: 14px;color: #999;margin-top: 10px;
  i {margin-left: 10px;}
}

.actionbtns {
  padding-bottom: 30px;
  i {font-size: 24px;color: #555;margin-right: 10px;}
}

nav.nextprevlinks {
  .nextpagelink {
    display: block;border: 1px solid #999;padding: 10px;text-decoration: none;
    &:hover {background-color: #eee;}
    .title {display: block;font-size: 18px;font-weight: bold;color: #333;}
    .nr {color: #000;}
    i.fa-chevron-right {font-size: 24px;line-height: 50px;color: #666;margin-right: 10px;}
  }
  ul {
    list-style: none;padding: 0;
    .nr {margin-right: 5px;}
  }
}

.relarticle {
  position: relative;margin-bottom: 30px;
  .bgimg {
    position: relative;height: 130px;width: 100%;background-color: #000;overflow: hidden;border-radius: 4px;
    img {display: block;width: 100%;opacity: .3;}
  }
  .text {
    position: absolute;top: 0;right: 0;bottom: 0;left: 0;color: #fff;padding: 15px;
    a {color: #8ebb16;font-size: 20px;}
  }
}

.searchbox{
  width:100%;position:relative;
  .form-control{border-radius:unset;}
  button{position:absolute;top:0;right:0;height:100%;font-size:1.2rem;padding:0 1rem;cursor:pointer;}
  @include media-breakpoint-up(md) {width:50%;}
}

#searchresultcontainer{display:none;}
.searchresults{list-style:circle;padding-left:20px;line-height:1.5rem;}

ul.infosites{
  li{margin-bottom:8px;}
  span{display:block;color:grey;font-size:.8rem;}
}

footer {
  background-color: #535252;padding: 30px 45px;
  .inner {background-color: #333;color: #fff;padding: 20px;}
  h2 {color: #999;font-size: 24px;font-weight: bold;}
  ul {list-style: none;padding: 0;margin: 0;}
  a, a:hover {color: #8ebb16;}
  a.privacy{color:grey;}
}

.darkbg {position: absolute;top: 51px;left: 0;width: 100%;height: 100%;background-color: rgba(0, 0, 0, .5);display: none;}

@include media-breakpoint-up(lg) {
  header.mobile {display: none !important;}
  nav.leftmenu {float: left;width: 24%;position: inherit;transform: none !important;}
  .rightside {float: left;width: 76%;transform: none !important;}
  nav.nextprevlinks ul {width: 50%;}
}


@media print{
  @page{margin:25mm;}
  footer{
    padding:0;
    .inner{color:#000;}
  }
  .content{padding-bottom:0;}

  body{background:#fff;color:#000;}
  nav.leftmenu,
  header.mobile,
  .picholder,
  nav.breadcrumbs,
  footer div.col-md-6:nth-child(2),
  div.actionbtns,
  a.nextpagelink{display:none !important;}
}